import React from "react"
import { graphql, Link } from "gatsby"
import Header from '../components/Header'
import 'bootstrap/dist/css/bootstrap.min.css'
import '../../scss/custom.scss'
import Card from 'react-bootstrap/Card'
import Img from 'gatsby-image'
import SiteMetadata from '../components/SiteMetadata'
// var _ = require("lodash")

const Layout = ({data}) => {
    //need to remove any blogs that aren't published yet. Show them in DEV, but not PROD.
    //const publishedBlogs = _.filter(data.allMdx.edges, function(blog){ return blog.node.frontmatter.isPublished })
    //const blogs = process.env.NODE_ENV === 'production' ? publishedBlogs : data.allMdx.edges
    const blogs = data.allMdx.edges

    return(
        <div>
            <SiteMetadata pathname="/" pagetitle=""/>
            <div><Header /></div>
            <div id='wrapper' style={{
                marginTop:'64px'
            }}>
                <div id='left-column' style={{
                    minHeight: '100%',
                    flexBasis: '20%',
                    flexGrow: '1'
                    }}>
                </div>

                <div id='middle-column' className="container-md">
                    {blogs.map(blog => {
                        const heroImg = blog.node.frontmatter.hero ? <Img key={blog.node.id} fluid={blog.node.frontmatter.hero.childImageSharp.fluid}/> : null
                        const timeToRead = blog.node.timeToRead
                        const relativeDate = blog.node.frontmatter.date

                        return(
                            <div style={{marginBottom:'24px'}} key={blog.node.id}>
                                <Card>
                                    {/* Add the hero image to the card, if one exists */}
                                    {heroImg}
                                    <Card.Body>
                                        <Card.Title style={{fontFamily:"Georgia"}}>
                                            <Link to={blog.node.fields.slug}>{blog.node.frontmatter.title}</Link>
                                        </Card.Title>
                                        <Card.Text>
                                            {blog.node.frontmatter.excerpt}
                                        </Card.Text>
                                    </Card.Body>
                                    <Card.Footer>
                                        <small className="text-muted">{relativeDate} · {timeToRead} min read</small>
                                    </Card.Footer>
                                </Card>
                            </div>
                        )
                    })} 
                    <div style={{marginBottom:'128px'}}>
                        {/* <Link to='/tags'>Browse by tag</Link> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export const query = graphql`
query HomepageQuery {
    allMdx (
        sort: {order: DESC, fields: [frontmatter___date]}
    ) {
        edges {
            node {
                id
                frontmatter {
                title
                date (fromNow: true)
                excerpt
                isPublished
                hero {
                    childImageSharp{
                        fluid {
                            ...GatsbyImageSharpFluid    
                        }
                    }
                }
                }
                timeToRead
                fields {
                    slug
                }
            }
        }
    }
}
`

export default Layout